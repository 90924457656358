<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <exportar-bdgd-distribuidora-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getExportacoes"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExportacoesBdgdsDistribuidorasService from '@/services/ExportacoesBdgdsDistribuidorasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    ExportarBdgdDistribuidoraDataTable: () =>
      import(
        '@/components/geracao-bdgd/extrair-bdgd/ExportarBdgdDistribuidoraDataTable'
      )
  },

  data: () => ({
    items: [],
    arrTour: [
      {
        element: '#btn-nova-exportacao',
        intro:
          "Clicando neste botão você poderá exportar BDGD's a partir do banco de dados da distribuidora.",
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#exportacoes-historico-search',
        intro:
          "Neste campo você poderá pesquisar por exportações de BDGD's já realizadas.",
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#exportacoes-column-entity',
        intro:
          'Clicando sobre essa coluna é possível visualizar a lista de entidades que foram selecionadas para a exportação.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#exportacoes-numero-linhas',
        intro:
          'Aqui você poderá visualizar o número total de linhas que foram exportadas.',
        scrollTo: 'tooltip',
        position: 'left'
      },
      {
        element: '#action-download-bdgd',
        intro:
          'Quando a exportação finalizar com o status sucesso, será possível baixar os arquivos da BDGD clicando aqui.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.refreshData(this.getExportacoes);
  },

  methods: {
    getExportacoes() {
      this.loading = true;

      ExportacoesBdgdsDistribuidorasService.getExportacoes()
        .then((responseData) => {
          this.items = responseData;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>
